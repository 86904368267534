<template>
  <header class="p-4 text-white bg-carbon">
    <nav class="container flex items-center justify-between mx-auto">
      <div v-if="authUser" class="flex items-center">
        <router-link class="mr-4" :to="{name: RoutesList.home}">
          <img class="w-6 h-6" src="/icons/logo.svg"/>
          <span class="sr-only">Dashboard</span>
        </router-link>
        <div v-if="currentWorkspaceName" class="py-2 px-4 ">
          <span>Workspace: </span>
          <router-link :to="{name: RoutesList.workspaceDetail}" class="font-bold">{{ currentWorkspaceName }}</router-link>
        </div>
      </div>
      <router-link to="/" v-else>
        <img class="w-6 h-6 text-white" src="/icons/logo.svg"/>
      </router-link>
      <div class="inline-flex items-center space-x-5" v-if="authUser">
        <span class="py-2 px-4">{{ authUser.name }}</span>
        <Logout/>
      </div>
      <router-link v-else :to="{name: RoutesList.login}" class="py-2 px-4 inline-flex items-center space-x-2">
        <span>Login</span>
      </router-link>
    </nav>
  </header>
</template>

<script>
import {mapGetters} from "vuex";
import Logout from "@components/Logout";
import HomeIcon from "@components/icons/HomeIcon";
import LoginIcon from "@components/icons/LoginIcon";
import {RoutesList} from "@/router/routesList";

export default {
  name: "Header",
  components: {
    Logout,
    HomeIcon,
    LoginIcon,
  },
  computed: {
    ...mapGetters("auth", ["authUser", "isAdmin"]),
    RoutesList() {
      return RoutesList
    },
    currentWorkspaceName() {
        const workspaces = this.$store.state.workspaces || [];
        return workspaces.find(workspace => workspace.id == this.$route.params.workspace_id)?.name ?? null;
    }
  },
};
</script>
