<template>
  <div>

    <div class="absolute top-0 left-0 w-full h-full z-40 grid grid-cols-2">

      <div>
        <img class="w-full h-full block object-cover" src="/assets/images/bg1.png"/>
      </div>
      <div class="p-12 pb-0  flex flex-col items-stretch justify-between">
        <div></div>
        <div>

          <div class="text-center mb-10">
            <img class="block mx-auto" src="/assets/logo-long.svg" width="200" style="height: auto"/>
          </div>

          <page-loader v-if="loadingUser"/>
          <div v-else class="p-10 bg-white text-center w-1/2 mx-auto">
            <h2 class="mb-4 text-2xl font-bold text-center">Sign in</h2>
            <p>Currently only Google login is supported.</p>
            <div class="text-center mt-10">
              <button class="px-4 py-2 bg-carbon text-white" @click="googleAuth">
                Sign in with Google
              </button>
              <div class="mt-2 text-sm text-gray-600">
                <router-link :to="{name: RoutesList.articleLoginIssues}">Cant login?</router-link>
              </div>
            </div>
          </div>

        </div>

        <div class="py-6 text-center">
          <a href="" class="underline">Privacy policy</a> and <a href="" class="underline">Terms of Service</a> apply.
          <br> Filuta.ai &copy; 2022
        </div>
      </div>


    </div>


  </div>
</template>

<script>
import authMixin from "@/mixins/authMixin";
import {RoutesList} from "@/router/routesList";
import PageLoader from "@components/PageLoader.vue";

export default {
  name: "LoginView",
  mixins: [authMixin],
  components: {PageLoader},
  computed: {
    loadingUser() {
      return this.$store.state.auth.loading;
    },
    RoutesList() {
      return RoutesList
    }
  },
  methods: {
    googleAuth() {
      const googleUrl = process.env.VUE_APP_OAUTH_GOOGLE_REDIRECT_URL;
      // this.initAuth(googleUrl).then((res) => {
        const reply = this.$store.dispatch("auth/getAuthUser");

        reply.then(user => {

          if (user?.id) {

            let callbackUrl = this.$route.query?.redirect ?? null;
            if (callbackUrl) {
              this.$router.push({
                path: callbackUrl
              })
            } else if (this.$route.name !== RoutesList.home) {
              this.$router.push({
                name: RoutesList.home
              })
            }

          }

        }).catch(e => {
          this.showGlobalError(e, "Sorry, something went wrong, please contact our support.");
        });

      // });
    },
  },
};
</script>
