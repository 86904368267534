import {Store} from "vuex";

export default function guest({next, store}: { next: any; store: Store<any> }) {
  const storageItem = window.localStorage.getItem("guest");
  if (storageItem === "isNotGuest" && !store.getters["auth/authUser"]) {
    store.dispatch("auth/getAuthUser").then(() => {
      if (store.getters["auth/authUser"]) {
        next();
      } else {
        store.dispatch("auth/setGuest", {value: "isGuest"});
        next();
      }
    });
  } else {
    next();
  }
}
