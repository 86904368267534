<template>
    <div v-if="value" class="fixed top-0 left-0 w-full h-full flex items-center justify-center" style="z-index: 199">
        <div class="absolute top-0 left-0 w-full h-full bg-black opacity-60" @click="overlayClick"></div>
        <div class="bg-white relative  overflow-y-auto max-h-full" :class="bodyClass">
            <a v-if="showCloseButton" class="absolute top-2 right-2 text-2xl cursor-pointer" @click="close">&times;</a>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "appDialog",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        medium: {
            type: Boolean,
            default: false,
        },
        large: {
            type: Boolean,
            default: false,
        },
        showCloseButton: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        bodyClass() {

            if (this.medium) {
                return "w-2/3";
            }

            if (this.large) {
                return "w-4/5";
            }

            return "w-1/2";
        }
    },
    methods: {
        close() {
            this.$emit("close");
            this.$emit("input", false);
        },
        overlayClick() {
            if (!this.persistent) {
                this.close();
            }
        }
    }
}
</script>
