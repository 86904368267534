export default {
  methods: {
    object2params(params: object) {
      return Object.entries(params)
        .map((i) => i[0] + "=" + i[1])
        .join(",");
    },

    initAuth(url: string) {
      return new Promise((resolve) => {
        let popup_width = 600;
        let popup_height = 600;

        let left_position = 50;
        let top_position = 50;

        let params = this.object2params({
          popup: 1,
          width: popup_width,
          height: popup_height,
          left: left_position,
          top: top_position,
          screenX: left_position,
          screenY: top_position,
        });

        let popupWindow = window.open(url, "popup", params);
        if (popupWindow) {
          popupWindow.focus();
        }

        let checkConnect = setInterval(() => {
          if (!popupWindow || !popupWindow.closed) return;
          clearInterval(checkConnect);
          resolve(true);
        }, 100);
      });
    },

  },
};
