<template>
    <div class="app">
        <Header v-if="showHeader"/>
        <div class="main">
            <LoginView v-if="showLoginPage"/>
            <router-view :key="$route.fullPath" v-else/>
        </div>
    </div>
</template>

<script>
import Header from "./components/Header.vue";
import {RoutesList} from "@/router/routesList";
import PageLoader from "@components/PageLoader.vue";
import LoginView from "@pages/Login.vue";
import apiMixin from "@/mixins/apiMixin";

export default {
    name: "App",
    components: {LoginView, PageLoader, Header},
    mixins: [apiMixin],
    data() {
        return {}
    },
    computed: {

        loading() {
            return !this.$route.name;
        },

        guestRoutes() {
            const guestRoutes = [
                RoutesList.articleLoginIssues
            ];
            return guestRoutes;
        },

        showHeader() {

            if (this.showLoginPage)
                return false;

            const disabledRoutes = [
                ...this.guestRoutes,
                RoutesList.login,
            ];


            return !disabledRoutes.includes(this.$route.name);
        },

        showLoginPage() {

            const routeName = this.$route.name;
            if (this.guestRoutes.includes(routeName)) {
                return false;
            }

            if (this.$store.getters["auth/authUser"]) {
                return false;
            }

            return true;
        },
    },

    mounted() {
        this.loadAllWorkspaces();
    }
};
</script>
