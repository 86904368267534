<template>
    <button type="button" @click="logout" class="py-2 px-4 inline-flex items-center space-x-2">
        <span class="hidden sm:inline">Logout</span>
    </button>
</template>

<script>
import LogoutIcon from "@components/icons/LogoutIcon";

export default {
    components: {LogoutIcon},
    name: "Logout",
    methods: {
        logout() {
        },
    },
};
</script>
