export default {
  methods: {
    showGlobalError(e) {
      this.$toast.error("Error, please try it again or contact support.");
    },
    removeReactivity(str) {
      return JSON.parse(JSON.stringify(str));
    }
  }
}
