import {RoutesList} from "@/router/routesList";

export default function auth({to, next, store}) {
  const loginQuery = {path: RoutesList.login, query: {redirect: to.fullPath}};

  if (!store.getters["auth/authUser"]) {
    store.dispatch("auth/getAuthUser").then(() => {
      if (!store.getters["auth/authUser"]) {

        if (![RoutesList.home, RoutesList.login].includes(to.name)) {
          return next(loginQuery);
        }

      }
      return next();
    });
  } else {
    next();
  }
}
