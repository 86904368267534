import {apiClient} from "@/services/apiService";

export default {
    methods: {
        loadAllWorkspaces() {
            apiClient.get("/dashboard").then(({data}) => {
                this.$store.commit("setWorkspaces", data);
            }).catch(e => {
                console.error(e);
            });
        },
    }
}
