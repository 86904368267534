import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store';
import apiMixin from "@/mixins/apiMixin";

import "@/assets/css/tailwind.css";
import "@/assets/css/app.scss";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import appMixin from "@/mixins/appMixin";
import appTextField from "@components/appTextField.vue";
import appBtn from "@components/appBtn.vue";
import appDialog from "@components/appDialog.vue";
import appIcon from "@components/appIcon.vue";

Vue.use(Toast, {
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false,
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
});

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
    "field",
    "block",
    "category",
    "xml",
    "mutation",
    "value",
    "sep",
    "html:button",
];

Vue.mixin(apiMixin);
Vue.mixin(appMixin);

Vue.component("app-text-field", appTextField);
Vue.component("app-btn", appBtn);
Vue.component("app-dialog", appDialog);
Vue.component("app-icon", appIcon);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
