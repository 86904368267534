<template>
  <svg class="icon">
    <use :xlink:href="iconHref"></use>
  </svg>
</template>

<script>
export default {
  name: "appIcon",
  props: {
    icon: {
      type: String,
      default: "",
    }
  },
  computed: {
    iconHref() {
      return "/assets/sprite_defs.svg#" + this.icon;
    }
  }
}
</script>
