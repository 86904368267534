export enum RoutesList {
  home = "home",
  login = "login",
  workspaces = "workspaces",
  workspaceDetail = "workspaceDetail",
  workspaceModels = "workspaceModels",
  workspaceNewModel = "workspaceNewModel",
  workspaceEditModel = "workspaceEditModel",
  workspaceCompositions = "workspaceCompositions",
  workspaceNewComposition = "workspaceNewComposition",
  workspaceEditComposition = "workspaceEditComposition",
  workspaceDeployments = "workspaceDeployments",
  workspaceEditDeployment = "workspaceEditDeployment",
  workspaceDeploymentDetail = "workspaceDeploymentDetail",
  articleLoginIssues = "articleLoginIssues",
  notFound = "notFound",
}
