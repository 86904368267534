<template>
  <button :class="classes" @click="click">
      <span :class="{'opacity-0': loading}">
        <slot></slot>
      </span>
      <span v-if="loading" class="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <Loader/>
      </span>
  </button>
</template>

<script>
import Loader from "@components/Loader.vue";

export default {
  name: "appBtn",
  components: {Loader},
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      let classes = [];

      if (!this.inline) {
        classes.push("btn");
      }

      if (this.disabled || this.loading) {
        classes.push("btn--disabled");
      }

      return classes.join(" ");
    }
  },
  methods: {
    click() {
      if (!this.disabled) {
        this.$emit("btn");
      }
    }
  }
}
</script>
