import axios from "axios";
import {axiosRequestInterceptor, axiosResponseInterceptor} from "@/services/interceptors";

const apiClientInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
});

apiClientInstance.interceptors.request.use(axiosRequestInterceptor);
apiClientInstance.interceptors.response.use(...axiosResponseInterceptor);

export const apiClient = apiClientInstance;

export default {
    getAuthUser(): Promise<any> {
        return apiClient.post("/user", {
            email: "tintin@filuta.ai" //@todo remove once BE sends cookie with auth token
        }).catch(e => {
            console.error(e);
        });
    },
};