<template>
  <div class="h-40 flex items-center justify-center">
    <Loader class="w-20 h-20"/>
  </div>
</template>

<script>
import Loader from "@components/Loader.vue";

export default {
  name: "PageLoader",
  components: {Loader}
}
</script>
