import guest from "@/middleware/guest";
import auth from "@/middleware/auth";
import {RoutesList} from "@/router/routesList";

export var routes = [
  {
    path: "/",
    name: RoutesList.home,
    meta: {middleware: [auth]},
    component: () => import("@pages/Dashboard.vue"),
  },
  {
    path: "/login-issues",
    name: RoutesList.articleLoginIssues,
    meta: {middleware: [guest]},
    component: () => import("@pages/articles/LoginIssues.vue"),
  },
  {
    path: "/login",
    name: RoutesList.login,
    meta: {middleware: [guest]},
    component: () => import("@pages/Login.vue"),
  },
  {
    path: "/workspaces",
    name: RoutesList.workspaces,
    meta: {middleware: [auth]},
    component: () => import("@pages/Workspaces.vue"),
  },
  {
    path: "/workspace/:workspace_id",
    meta: {middleware: [auth]},
    component: () => import("@pages/workspace_detail/WorkspaceDetailWrapper.vue"),
    children: [
      {
        path: "",
        name: RoutesList.workspaceDetail,
        meta: {middleware: [auth]},
        component: () => import("@pages/workspace_detail/Dashboard.vue"),
      },
      {
        path: "models",
        name: RoutesList.workspaceModels,
        meta: {middleware: [auth]},
        component: () => import("@pages/workspace_detail/Models.vue"),
      },
      {
        path: "models/new",
        name: RoutesList.workspaceNewModel,
        meta: {middleware: [auth]},
        component: () => import("@pages/workspace_detail/model/EditModel.vue"),
      },
      {
        path: "models/:model_id",
        name: RoutesList.workspaceEditModel,
        meta: {middleware: [auth]},
        component: () => import("@pages/workspace_detail/model/EditModel.vue"),
      },
      {
        path: "compositions",
        name: RoutesList.workspaceCompositions,
        meta: {middleware: [auth]},
        component: () => import("@pages/workspace_detail/Compositions.vue"),
      },
      {
        path: "compositions/new",
        name: RoutesList.workspaceNewComposition,
        meta: {middleware: [auth]},
        component: () => import("@pages/workspace_detail/composition/EditComposition.vue"),
      },
      {
        path: "compositions/:composition_id",
        name: RoutesList.workspaceEditComposition,
        meta: {middleware: [auth]},
        component: () => import("@pages/workspace_detail/composition/EditComposition.vue"),
      },
      {
        path: "deployments",
        name: RoutesList.workspaceDeployments,
        meta: {middleware: [auth]},
        component: () => import("@pages/workspace_detail/Deployments.vue"),
      },
      {
        path: "deployments/:deployment_id/detail",
        name: RoutesList.workspaceDeploymentDetail,
        meta: {middleware: [auth]},
        component: () => import("@pages/workspace_detail/deployment/DeploymentDetail.vue"),
      },
    ]
  },
  {
    path: "/:catchAll(.*)",
    name: RoutesList.notFound,
    component: () => import("@pages/NotFound.vue"),
  },
];
