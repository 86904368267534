<template>
  <div>
    <input :class="classes" type="text" v-model="computedValue" :placeholder="label" :disabled="disabled">
  </div>
</template>

<script>
export default {
  name: "appTextField",
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      computedValue: "",
    }
  },
  computed: {
    classes() {

      let classes = ["p-2"];

      if (this.disabled) {
        classes.push("bg-gray-200");
      }

      return classes.join(" ");
    }
  },
  watch: {
    value: {
      handler(v) {
        this.computedValue = this.removeReactivity(v);
      },
      immediate: true
    },
    computedValue: {
      handler(v) {
        this.$emit("input", v);
      }
    }
  },
  methods: {}
}
</script>
